import React from "react";
import {
  StyledImageWithCards,
  StyledImageWithCardsBlocks,
  StyledImageWithCardsBlock,
  StyledImageWithCardsImage,
  StyledImageWithCardsItems,
  StyledImageWithCardsItem,
  StyledImageWithCardsItemIcon,
  StyledImageWithCardsItemTitle,
  StyledImageWithCardsItemSubtitle,
  StyledImageWithCardsItemParagraph,
} from "./style.jsx";
import { StyledGridRow } from "../../commons/Grid";
import Intro from "../Intro/Intro";

export default ({ intro, image, items }) => {
  return (
    <StyledImageWithCards>
      <StyledGridRow isFull>
        <Intro title={intro.title} bigText={intro.bigText} />
        <StyledImageWithCardsBlocks>
          <StyledImageWithCardsBlock>
            <StyledImageWithCardsImage bg={image} />
          </StyledImageWithCardsBlock>
          <StyledImageWithCardsBlock>
            <StyledGridRow noMargin>
              <StyledImageWithCardsItems>
                {items.map((item, index) => (
                  <StyledImageWithCardsItem key={index}>
                    <StyledImageWithCardsItemIcon src={item.icon} loading="lazy" />
                    <StyledImageWithCardsItemTitle>
                      {item.title}
                    </StyledImageWithCardsItemTitle>
                    <StyledImageWithCardsItemSubtitle>
                      {item.subtitle}
                    </StyledImageWithCardsItemSubtitle>
                    <StyledImageWithCardsItemParagraph>
                      {item.paragraph}
                    </StyledImageWithCardsItemParagraph>
                  </StyledImageWithCardsItem>
                ))}
              </StyledImageWithCardsItems>
            </StyledGridRow>
          </StyledImageWithCardsBlock>
        </StyledImageWithCardsBlocks>
      </StyledGridRow>
    </StyledImageWithCards>
  );
};
